import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-status-label';
import 'pli/pli-user-bubble-list';
import 'pli/pli-input';
import 'pli/pli-button';
import 'pli/pli-status-label';
import 'pli/pli-textarea';
import 'pli/pli-alert-box';

import './components/case-details-comments';
import './components/case-details-alerts';
import './components/case-details-documents';
import 'shared/transaction/transaction-history-area';

import db from 'just-debounce';
import { Task } from '@lit/task';
import { styles } from 'pli/styles';
import { consume } from '@lit/context';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { PliUserBubbleListProps } from '../../pli/pli-user-bubble-list';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { DialogService, dialogServiceContext } from 'context/dialog-service-context';
import { ErrorService, errorServiceContext } from 'context/error-service-context';
import { UserModel as LegacyUserModel, UserSelectModel } from 'user-model';
import { DocumentModel as LegacyDocumentModel } from 'document-model';
import { ReportDialog, Model as ReportModel } from '../../customers/details/case-details/report-dialog/report-dialog';
import { CloseDialog, Model as CloseModel } from '../../customers/details/case-details/close-dialog/close-dialog';
import { OpenDialog, Model as OpenModel } from '../../customers/details/case-details/open-dialog/open-dialog';
import { AssignDialog, AssignDialogModel } from '../../services/assign-dialog/assign-dialog';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { CaseApi, caseApiContext } from 'context/api/case/case-api-context';
import { UserApi, userApiContext } from 'context/api/user/user-api-context';
import { CaseDetails, caseDetailsSchema } from 'schema/case/case-details-schema';
import { DocumentModel } from 'schema/document/document-schema';
import { User } from 'schema/user/user-schema';
import { when } from 'lit/directives/when.js';
import { Router, routerContext } from 'context/router-context';
import { CaseStatus } from 'domain/status/case-status';
import { Issue, ValidationController } from 'controllers/validation-controller';
import { mainSchema, Payload, SchemaKey } from './schema';
import { PliInputProps } from 'pli/pli-input';
import { customerDetailsContext } from 'context/details/customer-details-context';
import { CustomerDetailsMapped, customerType } from 'schema/customer-details-schema';

@customElement('cases-details')
class CasesDetails extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @consume({ context: caseApiContext })
    _caseApi?: CaseApi;

    @consume({ context: userApiContext })
    _userApi?: UserApi;

    @consume({ context: routerContext })
    _router?: Router;

    @consume({ context: customerDetailsContext, subscribe: true })
    customerDetails: CustomerDetailsMapped | null = null;

    _caseModel: CaseDetails = caseDetailsSchema.default({});

    @state()
    _caseId: string | null = null;

    @state()
    _documents: DocumentModel[] = [];

    @state()
    _assigned: User[] = [];

    _users: User[] = [];

    _issues: Issue[];

    static styles = [
        styles.base,
        styles.grid,
        styles.form,
        styles.position,
        css`
            :host {
                --button-group-top: var(--size-2-5);
            }
            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }
        `,
    ];

    @consume({ context: dialogServiceContext })
    dialogService?: DialogService;

    @consume({ context: errorServiceContext })
    errorService?: ErrorService;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    _validationController = new ValidationController(this, mainSchema);

    connectedCallback(): void {
        super.connectedCallback();
        this._caseId = this.caseId ?? null;
    }

    _task = new Task(this, {
        task: async ([caseId]) => {
            await Promise.all([this.loadCase(caseId), this.loadUsers()]);
        },
        args: () => [this._caseId] as const,
    });

    private async loadCase(caseId: string) {
        if (!this._caseApi) {
            return;
        }

        this._caseModel = await this._caseApi.getById(caseId);
        this._documents = this._caseModel.documents;
        this._assigned = this._caseModel.assigned;
    }

    private async loadUsers() {
        try {
            if (!this._userApi) return;

            const response = await this._userApi.getAll();
            this._users = response.list;
        } catch (error) {}
    }

    onChange = () => {
        this._saveWithDebounce();
    };

    onChangeWithValidation = (payload: Payload) => {
        const { isValid } = this._validationController.parse(payload);
        this._issues = [...this._validationController.issues];

        if (!isValid) {
            return;
        }

        this._caseModel.name = payload.name;
        this._saveWithDebounce();
    };

    _saveWithDebounce = db(async () => {
        await this._caseApi.update(this.caseId, {
            name: this._caseModel.name,
            description: this._caseModel.description,
            comment: this._caseModel.comment,
        });
    }, 1000);

    get disabled(): boolean {
        return this._caseModel.closed;
    }

    get isOrganization(): boolean {
        return this.customerDetails && this.customerDetails.customerType === customerType.Values.Organization;
    }

    reportCaseDialog(caseId: string, documents: DocumentModel[]): void {
        const legacyDocumentModels = documents.map(
            (doc) =>
                new LegacyDocumentModel(
                    doc.documentId.toString(),
                    doc.uploadedTime,
                    new LegacyUserModel(
                        doc.uploadedBy.userId,
                        doc.uploadedBy.firstName,
                        doc.uploadedBy.lastName,
                        doc.uploadedBy.fullName,
                    ),
                    doc.name,
                    doc.contentType,
                ),
        );
        const model: ReportModel = new ReportModel(caseId, legacyDocumentModels);

        try {
            this.dialogService
                ?.open({
                    viewModel: ReportDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        this._router.navigate(`report-filing/${result.output}`);
                    }
                });
        } catch (error: unknown) {
            const { message } = error as { message: string };
            this.errorService?.showErrorDialog(message);
        }
    }

    closeCaseDialog(): void {
        const model: CloseModel = new CloseModel(this._caseId);

        try {
            this.dialogService
                ?.open({
                    viewModel: CloseDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        await this.loadCase(this._caseId);
                    }
                });
        } catch (error: unknown) {
            const { message } = error as { message: string };
            this.errorService?.showErrorDialog(message);
        }
    }

    openCaseDialog(): void {
        const model: OpenModel = new OpenModel(
            this._caseId,
            this._caseModel.status,
            this._caseModel.reportFilingId,
            this._caseModel.reportFilingName,
        );

        try {
            this.dialogService
                ?.open({
                    viewModel: OpenDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        await this.loadCase(this._caseId);
                    }
                });
        } catch (error: unknown) {
            const { message } = error as { message: string };
            this.errorService?.showErrorDialog(message);
        }
    }

    async assignDialog(): Promise<void> {
        const selectedUsers = this._users.map((user) => {
            const userAssigned = this._assigned.find((assigned) => user.userId === assigned.userId);
            const isSelected = userAssigned !== undefined;
            return new UserSelectModel(
                isSelected,
                new LegacyUserModel(user.userId, user.firstName, user.lastName, user.fullName),
            );
        });
        const model: AssignDialogModel = new AssignDialogModel(
            'Assign case',
            'Assign this case to yourself or your colleagues.',
            selectedUsers,
        );

        try {
            this.dialogService
                ?.open({
                    viewModel: AssignDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        if (result.output.isAssignedToMe) {
                            const currentUser = await this.currentUser.get();
                            await this._caseApi.assign({ caseId: this.caseId, userIds: [currentUser.userId] });
                            this._assigned = [currentUser];
                        } else {
                            await this._caseApi.assign({
                                caseId: this.caseId,
                                userIds: result.output.selectedUsers.map((u) => u.user.userId),
                            });
                            this._assigned = result.output.selectedUsers.map((selectedUser) => selectedUser.user);
                        }
                    }
                });
        } catch (error: unknown) {
            const { message } = error as { message: string };
            this.errorService?.showErrorDialog(message);
        }
    }

    getStateForField = (key: SchemaKey): PliInputProps['state'] => {
        const issue = this._issues?.find((issue) => issue.name === key);
        return Boolean(issue) ? 'invalid' : 'initial';
    };

    renderStatusLabel(): TemplateResult {
        if (this._caseModel.status) {
            return html`<pli-status-label variant="${this._caseModel.status}"
                >${this._caseModel.status}</pli-status-label
            >`;
        }
    }

    renderDate(): TemplateResult {
        if (this._caseModel.created) {
            return html`${dateTimeFormatter(this._caseModel.created.toString())}`;
        }
    }

    renderAssigned(): TemplateResult {
        if (this._assigned) {
            return html`<pli-user-bubble-list
                .items="${this._assigned as PliUserBubbleListProps['items']}"
            ></pli-user-bubble-list>`;
        }
    }

    renderConclusion(): TemplateResult {
        const investigating = html`<pli-alert-box variant="info" displayMode="text"
            ><pli-icon name="info-circle"></pli-icon>
            <p>A decision has not been made, this case is still under investigation.</p></pli-alert-box
        >`;
        const resolved = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <p>Case has been resolved and is now closed, read Summary to understand conclusion.</p></pli-alert-box
        >`;
        const reporting = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <div>
                <p>A decision to file a report has been made. The report is being written and ready to be submitted.</p>
                <pli-button variant="text" as="a" size="lg" href="report-filing/${this._caseModel.reportFilingId}">
                    ${this._caseModel.reportFilingName} ->
                </pli-button>
            </div></pli-alert-box
        >`;
        const reported = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <div>
                <p>A decision to file a report has been made. The report is submitted to the goAML portal.</p>
                <pli-button variant="text" as="a" size="lg" href="report-filing/${this._caseModel.reportFilingId}">
                    ${this._caseModel.reportFilingName} ->
                </pli-button>
            </div></pli-alert-box
        >`;
        const componentMap: Record<CaseStatus, TemplateResult> = {
            Investigating: investigating,
            Resolved: resolved,
            Reporting: reporting,
            Reported: reported,
        };
        return componentMap[this._caseModel.status];
    }

    renderReportCaseButton(): TemplateResult {
        return html`<pli-button
            size="lg"
            width="full"
            .onClick="${() =>
                this.reportCaseDialog(this._caseId, this._caseModel.documents)}"
            .disabled="${this.isOrganization}"
        >
            Report case
        </pli-button>`;
    }

    renderAssignButton(): TemplateResult {
        return html`<pli-button size="lg" width="full" .onClick="${() => this.assignDialog()}"> Assign </pli-button>`;
    }

    renderCloseButton(): TemplateResult {
        return html`<pli-button size="lg" width="full" variant="destructive" .onClick="${() => this.closeCaseDialog()}">
            Close case
        </pli-button>`;
    }

    renderOpenButton(): TemplateResult {
        return html`<pli-button size="lg" width="full" .onClick="${() => this.openCaseDialog()}">
            Open case
        </pli-button>`;
    }

    renderOpenButtonGroup(): TemplateResult {
        if (this._caseModel.status !== 'Reported' && this._caseModel.status !== 'Reporting') {
            return html` ${this.renderReportCaseButton()} ${this.renderAssignButton()} ${this.renderCloseButton()}`;
        } else {
            return html` ${this.renderAssignButton()} ${this.renderCloseButton()}`;
        }
    }

    renderClosedButtonGroup(): TemplateResult {
        return this.renderOpenButton();
    }

    renderErrorMessage(key: SchemaKey): TemplateResult {
        const issue = this._issues?.find((issue) => issue.name === key);
        if (Boolean(issue)) {
            return html`<div class="error">
                <pli-alert-box variant="error">${issue.message}</pli-alert-box>
            </div>`;
        }
    }

    render(): TemplateResult {
        return html`
            <div class="grid-vertical gap-1 relative wrapper">
                <pli-button
                    class="back-button"
                    variant="text"
                    as="a"
                    size="lg"
                    href="customers/${this._caseModel.customerId}"
                >
                    <pli-icon name="arrow-up-circle" slot="icon-left"></pli-icon>
                    Back to Investigations
                </pli-button>
                <pli-text as="h3" variant="h3">Case ${this._caseModel.name}</pli-text>
                <div class="grid gap-1">
                    <div class="col-span-10">
                        <div class="grid-vertical gap-1">
                            <div class="grid gap-1">
                                <pli-card class="col-span-6">
                                    <form class="grid-vertical gap-1" .inert="${this.disabled}">
                                        <pli-text as="h4" variant="h4">Overview</pli-text>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Name*</strong></label>
                                            <pli-input
                                                @change="${(event: PliInputChangeEvent) => {
                                                    this.onChangeWithValidation({ name: event.detail.value });
                                                }}"
                                                size="sm"
                                                placeholder="Case name"
                                                value="${this._caseModel.name}"
                                                state="${this.getStateForField('name')}"
                                            ></pli-input>
                                            ${this.renderErrorMessage('name')}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Description</strong></label>
                                            <pli-textarea
                                                value="${this._caseModel.description}"
                                                @change="${(event: PliInputChangeEvent) => {
                                                    this._caseModel.description = event.detail.value;
                                                    this.onChange();
                                                }}"
                                                placeholder=""
                                            ></pli-textarea>
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Status</strong></label>
                                            ${this.renderStatusLabel()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Created</strong></label>
                                            ${this.renderDate()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Assigned</strong></label>
                                            ${this.renderAssigned()}
                                        </div>
                                    </form>
                                </pli-card>
                                <pli-card class="col-span-6">
                                    <div class="grid-vertical gap-3">
                                        <form .inert="${this.disabled}">
                                            <div class="grid-vertical gap-05">
                                                <pli-text as="h4" variant="h4">Summary</pli-text>
                                                <pli-textarea
                                                    value="${this._caseModel.comment}"
                                                    @change="${(event: PliInputChangeEvent) => {
                                                        this._caseModel.comment = event.detail.value;
                                                        this.onChange();
                                                    }}"
                                                    placeholder=""
                                                ></pli-textarea>
                                            </div>
                                        </form>
                                        <div class="grid-vertical">
                                            <pli-text as="h4" variant="h4">Conclusion</pli-text>
                                            ${this.renderConclusion()}
                                        </div>
                                    </div>
                                </pli-card>
                            </div>

                            <case-details-comments
                                caseId="${this._caseId}"
                                .disabled="${this.disabled}"
                            ></case-details-comments>
                            ${when(
                                this._caseModel.customerId,
                                () =>
                                    html`<case-details-alerts
                                        caseId="${this._caseId}"
                                        customerId="${this._caseModel.customerId}"
                                        .disabled="${this.disabled}"
                                    ></case-details-alerts>`,
                            )}
                            <case-details-documents
                                caseId="${this._caseId}"
                                .disabled="${this.disabled}"
                            ></case-details-documents>
                            ${when(
                                this._caseModel.customerId,
                                () =>
                                    html`<transaction-history-area .customerId="${this._caseModel.customerId}">
                                        <pli-text as="h4" variant="h4" slot="header">Transaction History</pli-text>
                                    </transaction-history-area>`,
                            )}
                        </div>
                    </div>
                    <div class="col-span-2 button-group sticky">
                        <div class="grid-vertical gap-05">
                            ${when(
                                this._caseModel.closed,
                                () => this.renderClosedButtonGroup(),
                                () => this.renderOpenButtonGroup(),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
