import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'pli/pli-table';
import 'pli/pli-status-label';
import 'pli/pli-pagination';
import 'pli/pli-button';
import 'pli/pli-icon';
import 'shared/comments/comments-area';

import { defineHeaderItems } from 'pli/pli-table';
import { CaseApi, caseApiContext } from 'context/api/case/case-api-context';
import {
    CaseAlert,
    CaseAlertResponse,
    CaseAlertSortField,
    sortableAlertfieldEnum,
} from 'schema/case/case-details-alert-schema';
import { SortableOrder } from 'schema/pagination/pagination-schema';
import { StorageOrderController } from 'controllers/storage-order-controller';
import { getFormattedTime } from 'utils/datetime-formatter';
import { DialogService, dialogServiceContext } from 'context/dialog-service-context';
import { ErrorService, errorServiceContext } from 'context/error-service-context';
import { AlertsDialog, AlertsDialogModel } from 'customers/details/case-details/alerts-dialog/alerts-dialog';
import { when } from 'lit/directives/when.js';
import { styles } from 'pli/styles';

@customElement('case-details-alerts')
class CaseDetailsAlerts extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @property({ type: String, reflect: true })
    customerId?: string;

    @consume({ context: caseApiContext })
    _caseApi?: CaseApi;

    @consume({ context: dialogServiceContext })
    dialogService?: DialogService;

    @consume({ context: errorServiceContext })
    errorService?: ErrorService;

    @state()
    _total = 0;
    @state()
    _field: CaseAlertSortField | null = null;
    @state()
    _order: SortableOrder | null = null;
    @state()
    _alerts: CaseAlertResponse['list'] = [];

    _sortOrderKey: string = 'case-alert-list-order';
    _controller = new StorageOrderController(this, this._sortOrderKey);

    static styles = [styles.flex, styles.grid];

    _headerItems = defineHeaderItems({
        Id: {
            sortField: sortableAlertfieldEnum.enum.AlertId,
            columnSpan: 1,
        },
        Rule: {
            sortField: null,
            columnSpan: 1,
        },
        Status: {
            sortField: null,
            columnSpan: 2,
        },
        Created: {
            sortField: sortableAlertfieldEnum.enum.Created,
            columnSpan: 4,
        },
    });

    _task = new Task(this, {
        task: async ([field, order]) => {
            if (!this._caseApi) {
                return;
            }

            const { list, total } = await this._caseApi.getAlerts(this.caseId, {
                sort: {
                    order,
                    field: field as CaseAlertSortField,
                },
            });
            this._alerts = list.map((item) => {
                return { ...item, customerId: this.customerId };
            });
            this._total = total;
        },
        args: () => [this._controller.field, this._controller.order] as const,
    });

    private _sort(event: CustomEvent) {
        this._controller.field = event.detail.field;
        this._controller.order = event.detail.order;
    }

    private addAlertDialog(): void {
        const model: AlertsDialogModel = new AlertsDialogModel(this.caseId);

        try {
            this.dialogService
                ?.open({
                    viewModel: AlertsDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        const alerts = result.output;
                        for (const alert of alerts) {
                            await this._caseApi.addAlert(this.caseId, { alertId: alert.alertId });
                        }
                        this._task.run();
                    }
                });
        } catch (error: unknown) {
            const { message } = error as { message: string };
            this.errorService?.showErrorDialog(message);
        }
    }

    private async removeAlert(alertId: number): Promise<void> {
        if (this._alerts.length == 1) {
            return;
        }

        await this._caseApi.removeAlert(this.caseId, { alertId: alertId });
        this._task.run();
    }

    render(): TemplateResult {
        return html`
            <pli-card>
                <div class="flex justify-between items-center">
                    <pli-text variant="h4" as="h4">Attached Alerts</pli-text>
                    ${when(
                        !this.disabled,
                        () =>
                            html`<pli-button size="lg" width="hug-content" .onClick="${() => this.addAlertDialog()}">
                                <pli-icon slot="icon-left" name="plus"></pli-icon>
                                Add new alert
                            </pli-button>`,
                    )}
                </div>
                ${this._task.render({
                    pending: () =>
                        html`<pli-table
                            .loading="${true}"
                            .headerItems="${this._headerItems}"
                            loadingRowsCount="${10}"
                        ></pli-table>`,
                    complete: () => this.renderResult(),
                })}
            </pli-card>
        `;
    }

    private renderResult(): TemplateResult {
        const renderItem = (alert: CaseAlert): TemplateResult => html`
            <tr>
                <td>
                    <a data-link="navigate" href="customers/${alert.customerId}/alerts/${alert.alertId}/#investigations">
                        <strong>${alert.alertId}</strong>
                    </a>
                </td>
                <td>${alert.rule.name}</td>
                <td><pli-status-label variant="${alert.status}"></pli-status-label></td>
                <td>
                    <div class="flex justify-between items-center">
                        ${getFormattedTime(alert.created)}
                        ${when(
                            !this.disabled,
                            () =>
                                html`<pli-button
                                    data-link="prevent"
                                    size="lg"
                                    width="hug-content"
                                    variant="destructive"
                                    .onClick="${() => this.removeAlert(alert.alertId)}"
                                    .disabled="${this._alerts.length === 1}"
                                >
                                    <pli-icon slot="icon-left" name="trash"></pli-icon>
                                    Remove
                                </pli-button>`,
                        )}
                    </div>
                </td>
            </tr>
        `;
        return html`
            <div class="grid-vertical gap-2">
                <pli-table
                    @sort="${this._sort}"
                    .headerItems="${this._headerItems}"
                    .items="${this._alerts}"
                    .renderTemplate="${renderItem}"
                    sortOrderKey="${this._sortOrderKey}"
                >
                </pli-table>
            </div>
        `;
    }
}
