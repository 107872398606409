import { LitElement, TemplateResult, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import 'shared/comments/comments-area';

import { CaseApi, caseApiContext } from 'context/api/case/case-api-context';
import { CommentPaginatedResponse } from 'schema/comment/comment-schema';

@customElement('case-details-comments')
class CaseDetailsComments extends LitElement {
    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: caseApiContext })
    _caseApi?: CaseApi;

    @state()
    _comments: CommentPaginatedResponse['list'] = [];

    _task = new Task(this, {
        task: async ([]) => {
            await this.load();
        },
        args: () => [] as const,
    });

    private async load(): Promise<void> {
        if (!this._caseApi) {
            return;
        }

        const { list } = await this._caseApi.getComments(this.caseId);
        this._comments = list;
    }

    private async onAdd(event: any): Promise<void> {
        if (!this._caseApi) {
            return;
        }

        await this._caseApi.addComment({ caseId: this.caseId, comment: event.detail.value ?? '' });
        await this.load();
    }

    private async onEdit(event: any): Promise<void> {
        if (!this._caseApi) {
            return;
        }
        await this._caseApi.editComment({
            comment: event.detail.value ?? '',
            commentId: event.detail.commentId,
            caseId: this.caseId,
        });
        await this.load();
    }

    private async onReply(event: any): Promise<void> {
        if (!this._caseApi) {
            return;
        }
        const data = await this._caseApi.replyToComment({
            caseId: this.caseId,
            comment: event.detail.value ?? '',
            parentId: event.detail.parentId,
        });

        if (!data) {
            return;
        }
        await this.load();
    }

    private async onDelete(event: any): Promise<void> {
        if (!this._caseApi) {
            return;
        }
        const data = await this._caseApi.removeComment({
            caseId: this.caseId,
            commentId: event.detail.value,
        });
        if (!data) {
            return;
        }
        await this.load();
    }

    render(): TemplateResult {
        return html`
            <comments-area
                .comments="${this._comments}"
                @add="${this.onAdd}"
                @edit="${this.onEdit}"
                @reply="${this.onReply}"
                @delete="${this.onDelete}"
                .disabled="${this.disabled}"
            >
                <div class="grid-vertical gap-05">
                    <pli-text as="h4" variant="h4">Comments</pli-text>
                    <pli-text as="p">Share your notes and thought process</pli-text>
                </div>
            </comments-area>
        `;
    }
}
