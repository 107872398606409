import { datediff } from 'utils/date';
import { bindable } from 'aurelia-framework';

export class Deadline {
    @bindable date: string;

    days: number = 0;
    color: string = 'good';

    dateChanged(value: string) {
        if (value === undefined || value === null) {
            return;
        }

        this.days = datediff(new Date(), new Date(value));

        if (this.days >= 15) {
            this.color = 'good';
        } else if (this.days >= 6) {
            this.color = 'ok ';
        } else {
            this.color = 'bad ';
        }
    }
}
